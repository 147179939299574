import { render, staticRenderFns } from "./NewRole.vue?vue&type=template&id=6df7cc28&scoped=true&"
import script from "./NewRole.vue?vue&type=script&lang=js&"
export * from "./NewRole.vue?vue&type=script&lang=js&"
import style0 from "./NewRole.vue?vue&type=style&index=0&id=6df7cc28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df7cc28",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VContainer,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6df7cc28')) {
      api.createRecord('6df7cc28', component.options)
    } else {
      api.reload('6df7cc28', component.options)
    }
    module.hot.accept("./NewRole.vue?vue&type=template&id=6df7cc28&scoped=true&", function () {
      api.rerender('6df7cc28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/NewRole.vue"
export default component.exports