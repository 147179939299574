var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "new-role", attrs: { fluid: "" } },
    [
      _c("h2", { staticClass: "role__page-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("Role.createEditRolePage.createTitle")) +
            "\n  "
        )
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("RoleNameEdit", {
            attrs: { name: _vm.name, rules: _vm.nameRules },
            on: {
              "role-name-changed": function($event) {
                _vm.name = $event
              }
            }
          }),
          _c("hr"),
          _c("RolePermissions", {
            on: {
              "permissions-changed": function($event) {
                _vm.permissions = $event
              }
            }
          }),
          _c("SubmitBar", {
            attrs: {
              "confirm-btn-text": _vm.$t("Role.createEditRolePage.createTitle"),
              disabled: !_vm.valid,
              loading: _vm.loading
            },
            on: { "on-submit-clicked": _vm.onSubmit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }