<template>
  <v-container
    class="new-role"
    fluid
  >
    <h2 class="role__page-title">
      {{ $t('Role.createEditRolePage.createTitle') }}
    </h2>
    <v-form
      ref="form"
      v-model="valid"
    >
      <RoleNameEdit
        :name="name"
        :rules="nameRules"
        @role-name-changed="name = $event"
      />
      <hr>
      <RolePermissions
        @permissions-changed="permissions = $event"
      />
      <SubmitBar
        :confirm-btn-text="$t('Role.createEditRolePage.createTitle')"
        :disabled="!valid"
        :loading="loading"
        @on-submit-clicked="onSubmit"
      />
    </v-form>
  </v-container>
</template>

<script>
import RoleNameEdit from '@/components/permissions/RoleNameEdit';
import RolePermissions from '@/components/permissions/RolePermissions';
import SubmitBar from '@/components/permissions/SubmitBar';
import { mapActions, mapState } from 'vuex';
import { requiredRule, notEmpty, maxLength } from '@/utils/form_rules';
import requestStatusMixin from '@/mixins/request_status.mixin';

export default {
  name: 'NewRole',
  components: {
    RoleNameEdit,
    RolePermissions,
    SubmitBar
  },
  mixins: [requestStatusMixin],
  data() {
    return {
      name: '',
      permissions: [],
      valid: false,
      nameRules: [
        requiredRule(this.$t('FormRules.nameRules.required')),
        notEmpty(this.$t('FormRules.nameRules.notEmpty')),
        maxLength(100, this.$tc('FormRules.nameRules.maxLength', 100, { max: 100 }))
      ]
    };
  },
  computed: {
    ...mapState('role', ['requestStatus'])
  },
  methods: {
    ...mapActions('role', [
      'createRole'
    ]),
    async onSubmit() {
      const permissions = this.permissions
        .filter(permission => permission.isActive)
        .map(permission => permission.id);

      if (!permissions.length) {
        const errorMsg = this.$tc('FormRules.permissionsRules.minLength', 1, { min: 1 });
        this.$noty.error(errorMsg);
        return;
      }

      const data = {
        name: this.name,
        permissions
      };

      try {
        await this.createRole(data);
        this.reset();
        return this.$router.push({ name: 'role-list' });
      } catch (error) {
        const errorMsg = this.$t('Role.createEditRolePage.errorCreatingRole');
        this.$noty.error(errorMsg);
      }
    },
    reset() {
      /* istanbul ignore next */
      this.$refs.form.reset();
      /* istanbul ignore next */
      this.permissions = [];
    }
  }
};
</script>

<style lang="scss" scoped>

  .role {
    &__page-title {
      font-weight: bold;
    }
  }
</style>
